<template lang="pug">
  tr(
    :key="index"
    :class="{'updated' : item.animation}"
    data-test="candidate-row"
  ).row-animation
    td {{ item.candidateNumber }}
    td.nowrap {{ item.candidateBirthday }}
    td(:style="{maxWidth: '100px'}")
      | {{ item.fullName }}
      candidate-label(:model="item")
    td {{ item.candidateEmail }}
    td {{ item.candidateTel }}
</template>

<script>
import copyMixin from "@/mixins/copy.mixin"
import candidateRowMixin from "../../core/candidate-row.mixin"

export default {
  mixins: [ copyMixin, candidateRowMixin ],
  props: {
    item: {
      type: Object,
      requred: true
    },
    index: Number
  },

  components: {
    candidateLabel: () => import('../candidates/CandidatesLabel.vue')
  }
}
</script>

<style lang="scss" scoped>
.row-animation {
  transition: all 1s;
}

.updated {
  background-color: rgba($success-color, .3) !important;
}

.expanded {
  transform: rotate(90deg);
  color: $primary-color !important;
}


.table-dates {
  width: 170px;
  line-height: 1.3;
}

.table-reservation {
  margin: 0 -4px;
}

.table-date {
  font-size: 10px;

  &_main {
    font-weight: bold;
  }

  &_additional {
    color: $label-color;
    font-weight: 500;
  }
}

.app-table td {
  font-size: 12px;
  line-height: 1.3;
}

.app-table__row_expanded {
  > td {
    border-top: 1px solid $primary-color;

    &:first-child {
      border-left: 1px solid $primary-color;
    }

    &:last-child {
      border-right: 1px solid $primary-color;
    }
  }
}

.cdr {
  margin: 0 -4px;
}
</style>
