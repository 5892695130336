import { render, staticRenderFns } from "./CandidateRowSimilar.vue?vue&type=template&id=e597b984&scoped=true&lang=pug&"
import script from "./CandidateRowSimilar.vue?vue&type=script&lang=js&"
export * from "./CandidateRowSimilar.vue?vue&type=script&lang=js&"
import style0 from "./CandidateRowSimilar.vue?vue&type=style&index=0&id=e597b984&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e597b984",
  null
  
)

export default component.exports